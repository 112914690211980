<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  data() {
    return {
      store: {
        feature_options: {
          appearance: {
            hide_nav_title: false,
          },
        },
      },
    };
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Barre de navigation <AppFeatureBadge class="ml-3" position="is-right" />
    </template>
    <template #content>
      <p>
        Modifiez l'apparence de la barre fixe qui se trouve
        tout en haut de votre espace.
      </p>
    </template>
    <template #form>
      <GoProWrapper :hasSep="false">
        <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field>
            <b-switch v-model="store.feature_options.appearance.hide_nav_title">
              Cacher le titre situé à côté de votre logo
            </b-switch>
          </b-field>
          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
