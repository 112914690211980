<script>
import { storeForms } from '@app/mixins/forms';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';

export default {
  mixins: [storeForms],
  components: {
    VueSimpleEmbed,
  },
  i18n: {
    messages: {
      fr: {
        video_positions: {
          LEFT: 'À gauche de la description',
          RIGHT: 'À droite de la description',
          TOP: 'Au dessus de la description',
          BOTTOM: 'En dessous de la description',

        },
      },
    },
  },
  data() {
    return {
      videoPreviewUrl: '',
      store: {
        promotional_video_url: '',
        promotional_video_position: '',
      },
    };
  },
  computed: {
    videoPositions() {
      return Object.keys(this.$t('video_positions'));
    },
  },
  created() {
    this.store.promotional_video_position = this.store.promotional_video_position || 'TOP';
    this.videoPreviewUrl = this.store.promotional_video_url;
  },
  methods: {
    localHandle() {
      this.handle(() => {
        this.videoPreviewUrl = this.store.promotional_video_url;
      });
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Vidéo de présentation
    </template>
    <template #content>
      <p>
        Vous pouvez ajouter une vidéo de présentation sur votre page d'accueil à travers
        un lien <strong>YouTube</strong>, <strong>Vimeo</strong>,
        ou <strong>Dailymotion</strong>.
      </p>
      <p>Ajouter une vidéo rendra votre page dynamique !</p>
      <p class="has-text-weight-bold">
        Votre page d'accueil est accessible en
        <a :href="$store.getters['auth/url']" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </template>
    <template #form>
      <form class="box" @submit.prevent="dataIsValid(isLoading) && localHandle()">
        <b-field grouped>
          <b-field label="Vidéo de présentation" expanded>
            <b-input
              type="url"
              pattern="https?://.+"
              placeholder="Lien vers la vidéo"
              v-model="store.promotional_video_url"
            />
          </b-field>
          <b-field label="Disposition de la vidéo">
            <b-select v-model="store.promotional_video_position">
              <option
                v-for="position in videoPositions"
                :key="position"
                :value="position"
                v-t="`video_positions.${position}`"
              />
            </b-select>
          </b-field>
        </b-field>
        <div v-if="videoPreviewUrl" class="field">
          <VueSimpleEmbed
            class="has-text-centered"
            :attrs="{
              class: 'mw-full mx-auto',
              width: '560',
              height: '315'
            }"
            parser="plyr"
            :extraData="{nativePlayer: true}"
            :url="videoPreviewUrl"
          >
            <b-notification type="is-danger" :closable="false">
              Il y a eu un problème de chargement...
            </b-notification>
          </VueSimpleEmbed>
        </div>
        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </template>
  </AppFeatureWrapper>
</template>
