import { parsers } from './IframeEmbedRegExp';

const resolveParserAndMatches = (url, parser) => {
  const result = { parser, matches: null, url: parser.url };
  let { children } = parser;

  if (parser.extends) {
    children = parsers.filter((v) => parser.extends.includes(v.name));
  }

  if (parser.resolver) {
    return parser.resolver(url).then((embedURL) => {
      embedURL && (result.url = () => embedURL);
      return result;
    });
  }

  if (children) {
    const parsers = children;

    for (const i in parsers) {
      const matches = url.match(parsers[i].regex);

      if (matches) {
        result.parser = parsers[i];
        result.matches = matches;
        result.url = parsers[i].url;
        break;
      }
    }
  } else {
    result.matches = url.match(parser.regex);
  }

  return Promise.resolve(result);
};

export default null;
export const resolveUrl = (url = '', attrs, parser) => {
  const _parsers = parser ? parsers.filter((v) => v.name === parser) : parsers;

  const doResolve = (index) => {
    if (!_parsers[index]) {
      return Promise.resolve(false);
    }

    return resolveParserAndMatches(url, _parsers[index])
      .then((resolver) => {
        try {
          const iattrs = { ...resolver.parser.attrs, ...attrs };
          const src = resolver.url(resolver.matches);
          console.log(src, resolver.matches, iattrs);
          return { iattrs, src };
        } catch {
          return doResolve(index + 1);
        }
      });
  };

  return doResolve(0);
};
