<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  data() {
    return {
      store: {
        feature_options: {
          pages: {
            login: {
              title: '',
              description: '',
            },
          },
        },
      },
    };
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Connexion à votre espace
    </template>
    <template #content>
      <p>
        Vous pouvez personnaliser les textes de
        la page de connexion de vos apprenants.
      </p>
      <p>
        Le texte par défaut est "Bienvenue sur l'espace de formation"
      </p>
      <p class="has-text-weight-bold">
        La page de connexion est accessible en
        <a :href="`${$store.getters['auth/url']}/connexion`" target="_blank">cliquant ici</a>.
      </p>
    </template>
    <template #form>
      <GoProWrapper :hasSep="false">
        <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field label="Message d'accueil">
            <b-input
              maxlength="128"
              v-model="store.feature_options.pages.login.title"
            />
          </b-field>
          <b-field label="Courte description">
            <b-input
              type="textarea"
              maxlength="500"
              v-model="store.feature_options.pages.login.description"
            />
          </b-field>
          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
