<script>
import { storeForms } from '@app/mixins/forms';
import BaseColorField from '@app/components/BaseColorField.vue';

export default {
  mixins: [storeForms],
  components: {
    BaseColorField,
  },
  data() {
    return {
      store: {
        feature_options: {
          alert_message: {
            everywhere: '',
            customer: '',
            visitor: '',
            design: {
              color_text: null,
              color_background: null,
            },
          },
        },
      },
    };
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Bandeau d'annonce <AppFeatureBadge class="ml-3" position="is-right" />
    </template>
    <template #content>
      <p>
        Vous avez une annonce à faire sur votre espace de formation ?
        Ajoutez un bandeau d'annonce sur les pages de votre choix.
      </p>
      <p>
        Idéal pour communiquer sur une promo, un événement particulier...
      </p>
    </template>
    <template #form>
      <GoProWrapper :hasSep="false">
        <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field>
            <template #label>
              Bandeau visible sur toutes les pages
              <b-tooltip
                v-if="!!(store.feature_options.alert_message.customer || store.feature_options.alert_message.visitor)"
                multilined
              >
                <template #content>
                  Vous ne pouvez pas cumuler le bandeau global avec un bandeau spécifique.
                </template>
                <b-icon pack="far" icon="question-circle" />
              </b-tooltip>
            </template>
            <b-input
              rows="2"
              type="textarea"
              v-model.trim="store.feature_options.alert_message.everywhere"
              maxlength="500"
              :disabled="!!(store.feature_options.alert_message.customer || store.feature_options.alert_message.visitor)"
            />
          </b-field>
          <b-field>
            <template #label>
              Bandeau pour les pages des apprenants connectés
              <b-tooltip
                v-if="!!store.feature_options.alert_message.everywhere"
                multilined
              >
                <template #content>
                  Vous ne pouvez pas cumuler un bandeau spécifique avec le bandeau global.
                </template>
                <b-icon pack="far" icon="question-circle" />
              </b-tooltip>
            </template>
            <b-input
              rows="2"
              type="textarea"
              v-model.trim="store.feature_options.alert_message.customer"
              maxlength="500"
              :disabled="!!store.feature_options.alert_message.everywhere"
            />
          </b-field>
          <b-field>
            <template #label>
              Bandeau pour les pages des visiteurs non connectés
              <b-tooltip
                v-if="!!store.feature_options.alert_message.everywhere"
                multilined
              >
                <template #content>
                  Vous ne pouvez pas cumuler un bandeau spécifique avec le bandeau global.
                </template>
                <b-icon pack="far" icon="question-circle" />
              </b-tooltip>
            </template>
            <b-input
              rows="2"
              type="textarea"
              v-model.trim="store.feature_options.alert_message.visitor"
              maxlength="500"
              :disabled="!!store.feature_options.alert_message.everywhere"
            />
          </b-field>
          <b-field grouped>
            <b-field class="w-full is-flex-shrink-1" label="Couleur du texte du bandeau">
              <BaseColorField
                v-model.trim="store.feature_options.alert_message.design.color_text"
                pattern="^#[0-9A-Fa-f]{6}$"
              />
            </b-field>
            <b-field class="w-full is-flex-shrink-1" label="Couleur de fond du bandeau">
              <BaseColorField
                v-model.trim="store.feature_options.alert_message.design.color_background"
                pattern="^#[0-9A-Fa-f]{6}$"
              />
            </b-field>
          </b-field>
          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
