<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  data() {
    return {
      store: {
        feature_options: {
          pages: {
            home: {
              disabled: false,
            },
          },
        },
      },
    };
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Page de présentation de votre espace
    </template>
    <template #content>
      <p>
        Votre page d'accueil est celle qui liste toutes vos formations.
        Si vous souhaitez rediriger vos prospects et apprenants directement sur la page de connexion, désactivez la page d'accueil.
      </p>
      <p>
        Si vous ne vendez pas vos formations via Teachizy, cette option est recommandée.
      </p>
    </template>
    <template #form>
      <GoProWrapper :hasSep="false">
        <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field>
            <b-switch v-model="store.feature_options.pages.home.disabled">
              Désactiver la page d'accueil
            </b-switch>
          </b-field>
          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
