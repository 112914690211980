import axios from 'axios';

const { hostname } = window.location;

const itempropResolver = (url) => axios.get(url)
  .then(({ request, data }) => {
    const matches = data.match(
      /itemprop=["']embedURL["'].+content=["'](https?:\/\/[^"' ]+)["']/,
    );

    const embedURL = matches && matches[1];

    if (!embedURL && request.responseURL.match(/\/(mobile|htmlview)/)) {
      return request.responseURL;
    }

    return embedURL;
  })
  .catch(() => null);

const drive = {
  resolver: itempropResolver,
  attrs: {
    scrolling: 'no',
    frameborder: 'no',
    allowfullscreen: true,
    mozallowfullscreen: true,
    webkitallowfullscreen: true,
  },
};

const youtube = {
  regex: /^(?:.+\/|.+\.)?youtu(\.be\/|be(-nocookie|\.googleapis)?\.com.*(embed\/|live\/|vi?(=|%3D|\/)))([A-z0-9-_]{11})/,
  url: (matches) => `https://www.youtube.com/embed/${matches[5]}`,
  attrs: {
    frameborder: 0,
    allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
    allowfullscreen: true,
  },
};

export default null;
export const parsers = [
  {
    name: 'plyr',
    extends: ['youtube', 'vimeo'],
  },
  {
    name: 'calendly',
    resolver: (url) => Promise.resolve(url),
    attrs: {
      height: 690,
    },
  },
  {
    name: 'typeform',
    resolver: (url) => Promise.resolve(url),
    attrs: {
      height: 520,
    },
  },
  {
    name: 'youtube',
    ...youtube,
  },
  {
    name: 'youtube_live',
    ...youtube,
  },
  {
    name: 'youtube_live_chat',
    regex: youtube.regex,
    url: (matches) => `https://www.youtube.com/live_chat?v=${matches[5]}&embed_domain=${hostname}`,
    attrs: {
      frameborder: 'no',
      allowfullscreen: true,
    },
  },
  {
    name: 'dailymotion',
    regex: /^(?:.+\/|.+\.)?dai\.?ly(?:motion)?(?:\.com)?\/?.*(?:video|embed)?(?:.*v=|v\/|\/)([a-z0-9]+)/,
    url: (matches) => `https://www.dailymotion.com/embed/video/${matches[1]}`,
    attrs: {
      frameborder: 0,
      type: 'text/html',
      allowfullscreen: true,
    },
  },
  {
    name: 'vimeo',
    regex: /^(?:.+\/|.+\.)?vimeo\.com\/([0-9]+)(\/([0-9A-z]+))?/,
    url: (matches) => {
      const params = matches[3] ? `?h=${matches[3]}` : '';
      return `https://player.vimeo.com/video/${matches[1]}${params}`;
    },
    attrs: {
      frameborder: 0,
      allow: 'autoplay; fullscreen',
      allowfullscreen: true,
    },
  },
  {
    name: 'giphy',
    regex: /^(?:.+\/|.+\.)?giphy\.com\/(?:(?:media|gifs)\/)?(?:.*-)?([A-z0-9]+)(?:(?:\/|\.).+)?/,
    url: (matches) => `https://giphy.com/embed/${matches[1]}`,
    attrs: {
      frameborder: 'no',
      allowfullscreen: true,
    },
  },
  {
    name: 'codepen',
    regex: /^(?:.+\/|.+\.)?codepen.io\/([A-z0-9_-]+)\/.+\/([A-z0-9_-]+)/,
    url: (matches) => `https://codepen.io/${matches[1]}/embed/${matches[2]}`,
    attrs: {
      scrolling: 'no',
      frameborder: 'no',
      allowtransparency: true,
      allowfullscreen: true,
    },
  },
  {
    name: 'twitch',
    children: [
      {
        regex: /^(?:.+\/|.+\.)?twitch\.tv\/(?:.+)?(?:\?collection=([A-z0-9_-]+))(.+)?/,
        url: (matches) => `https://player.twitch.tv/?collection=${matches[1]}&parent=${hostname}&autoplay=false`,
        attrs: {
          scrolling: 'no',
          frameborder: 'no',
          allowfullscreen: true,
        },
      },
      {
        regex: /^(?:.+\/|.+\.)?twitch\.tv\/videos\/([0-9]+)(.+)?/,
        url: (matches) => `https://player.twitch.tv/?video=${matches[1]}&parent=${hostname}&autoplay=false`,
        attrs: {
          scrolling: 'no',
          frameborder: 'no',
          allowfullscreen: true,
        },
      },
      {
        regex: /^(?:.+\/|.+\.)?twitch\.tv\/[A-z0-9_-]+\/clip\/([A-z0-9_-]+)(.+)?/,
        url: (matches) => `https://clips.twitch.tv/embed?clip=${matches[1]}&parent=${hostname}&autoplay=false`,
        attrs: {
          scrolling: 'no',
          frameborder: 'no',
          allowfullscreen: true,
        },
      },
      {
        regex: /^(?:.+\/|.+\.)?twitch\.tv\/((?!videos\/|embed\/?)[A-z0-9_-]+)(.+)?/,
        url: (matches) => `https://player.twitch.tv/?channel=${matches[1]}&parent=${hostname}`,
        attrs: {
          scrolling: 'no',
          frameborder: 'no',
          allowfullscreen: true,
        },
      },
      {
        regex: /^(?:https?:\/\/)player\.twitch\.tv\/.+/,
        url: (matches) => matches[0].replace('parent=', `parent=${hostname}&`),
        attrs: {
          scrolling: 'no',
          frameborder: 'no',
          allowfullscreen: true,
        },
      },
    ],
  },
  {
    name: 'soundcloud',
    regex: /^(?:.+\/|.+\.)?(soundcloud\.com.+)/,
    url: (matches) => `https://w.soundcloud.com/player/?url=${matches[1]}&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
    attrs: {
      scrolling: 'no',
      frameborder: 'no',
      allow: 'autoplay',
      allowfullscreen: true,
    },
  },
  { name: 'gdoc', ...drive },
  { name: 'gslide', ...drive },
  { name: 'gsheet', ...drive },
  {
    name: 'gcalendar',
    resolver: (url) => Promise.resolve(url),
    attrs: drive.attrs,
  },
  {
    name: 'slideshare',
    resolver: itempropResolver,
    attrs: {
      scrolling: 'no',
      frameborder: 0,
      marginwidth: 0,
      marginheight: 0,
      allowfullscreen: true,
    },
  },
  {
    name: 'jsfiddle',
    regex: /^(?:.+\/|.+\.)?(jsfiddle.net\/[A-z0-9/_-]+)(?:.+)?/,
    url: (matches) => `https://${matches[1].replace(/\/$/, '')}/embedded/#result`,
    attrs: {
      frameborder: 'no',
      allowfullscreen: true,
      allowpaymentrequest: true,
    },
  },
  {
    name: 'codesandbox',
    regex: /^(?:.+\/|.+\.)?codesandbox.io\/s\/([A-z0-9/_-]+)(?:.+)?(\?.+)?/,
    url: (matches) => `https://codesandbox.io/embed/${matches[1]}${matches[2] || ''}`,
    attrs: {
      frameborder: 'no',
      allowfullscreen: true,
      allowpaymentrequest: true,
    },
  },
  {
    name: 'figma',
    regex: /^(?:.+\/|.+\.)?(figma.com\/.+)/,
    url: (matches) => `https://www.figma.com/embed?embed_host=oembed&url=https://www.${matches[1]}`,
    attrs: {
      frameborder: 'no',
      allowfullscreen: true,
    },
  },
  {
    name: 'chatgpt',
    resolver: (url) => Promise.resolve(url),
  },
  {
    name: 'tiktok',
    resolver: (url) => Promise.resolve(url),
  },
];
