<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  data() {
    return {
      store: {
        feature_options: {
          pages: {
            research: {
              enabled: false,
            },
          },
        },
      },
    };
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Recherche par mot(s) clé(s)
    </template>
    <template #content>
      <p>
        Activez un champ de recherche en haut de votre page d'accueil
        pour permettre aux visiteurs de trouver rapidement
        les formations qu'ils recherchent.
      </p>
      <p>
        La recherche sera effectuée sur les noms et les descriptions de vos formations publiées.
      </p>
    </template>
    <template #form>
      <!-- <GoProWrapper :hasSep="false"> -->
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <b-switch v-model="store.feature_options.pages.research.enabled">
            Activer la recherche par mot(s) clé(s)
          </b-switch>
        </b-field>
        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
      <!-- </GoProWrapper> -->
    </template>
  </AppFeatureWrapper>
</template>
